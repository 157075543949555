require('./bootstrap');
const {toJSON} = require("lodash/seq");

$(window).resize(function () {})
$(window).scroll(function () {});

$(document).ready(() => {
    if($('.config-overview')) {
        configInit();
        openConfigOverlay();
        closeConfigOverlay();

        $('[data-toggle="popover"]').popover({
            html: true,
            template: '<div class="popover option-popover" role="tooltip"><div class="popover-arrow option-popover-arrow"></div><div class="popover-header"></div><div class="popover-body"></div></div>',
            trigger: 'focus',
            offset: '40, 20'
        });

        $('.config-btn--back').click((e) => {
            e.preventDefault();
            if($('.config-tabs > ul.nav > li > button.active', $(e.target).closest('.config-overlay')).parent().prev('li')) {
                $('.config-tabs > ul.nav > li > button.active', $(e.target).closest('.config-overlay')).parent().prev('li').find('button').trigger('click');
            }
        })

        $('.config-btn--next').click((e) => {
            e.preventDefault();
            if($('.config-tabs > ul.nav > li > button.active', $(e.target).closest('.config-overlay')).parent().next('li')) {
                $('.config-tabs > ul.nav > li > button.active', $(e.target).closest('.config-overlay')).parent().next('li').find('button').trigger('click');
            }
        })

        $('.config-overview input:not([readonly])').click((e) => {
            clickLogic(e.target);
            bgImageChanger(e.target);
            checkFields();
            getConfigPrice();
            saveConfigToSession();
        });

        $('.config-btn--finish').click((e) => {
            e.preventDefault();
            if($(e.target).hasClass('notFinished')) {
                let configOverlay = $(e.target).closest(".config-overlay");
                let configTabs = $(configOverlay).find(".config-tabs ul li")
                let tabContent = $(configOverlay).find(".tab-content .tab-pane")
                $.each(tabContent, function(index, element) {
                    $(configTabs[index]).find('button')
                        .css('background-color', '');
                    if($(element).find(".mandatory-field-text:not(.d-none)").length > 0) {
                        $(configTabs[index]).find('button')
                            .css('background-color', 'rgba(222, 19, 19, 0.75)');
                        $(configTabs[index]).find('button').delay(15000).queue(function(){
                            $(this).css('background-color', '').dequeue();
                        });
                    }
                })
                $('.config-notFinished-info-line').show().delay(15000).fadeOut();
            }
            else {
                checkFields();
                finishPart(e);
                $('body').removeClass('overlay-open');
            }
        });

        $( "#save_config_modal_form" ).submit(function( event ) {
            event.preventDefault();
            saveConfigToUser();
        });

        $( "#update_config_modal_form" ).submit(function( event ) {
            event.preventDefault();
            updateConfigToUser();
        });

        $( "#update_config_modal_form_manager" ).submit(function( event ) {
            event.preventDefault();
            updateConfigToUserFromManager();
        });

        $('#reset_config').click((e) => {
            e.preventDefault();
            resetConfig(e);
        });

        $('#reservate_object').click((e) => {
            e.preventDefault();
            reservateConfigToUser();
        });

        $('#final_config').click((e) => {
            e.preventDefault();
            markFinished();
        });

        getConfigPrice();
    }
});

function configInit () {
    $(".config-overview input").each(function() {
        if ($(this).prop('checked')) {
            clickLogic($(this));
            bgImageChanger($(this));
        }
    });
    checkFields();

    $('.config-overlay').on('shown.bs.tab', function (e) {
        let buttons = $('[data-bs-toggle="tab"]', e.currentTarget);
        let activeButton = e.target;
        let activeButtonIndex = buttons.index(activeButton);
        if(activeButtonIndex === 0) {
            $('.config-btn--back', e.currentTarget).hide();
            $('.config-btn--next', e.currentTarget).show();
        }
        else if (activeButtonIndex === (buttons.length-1)) {
            $('.config-btn--back', e.currentTarget).show();
            $('.config-btn--next', e.currentTarget).hide();
        }
        else {
            $('.config-btn--back', e.currentTarget).show();
            $('.config-btn--next', e.currentTarget).show();
        }
    })
}

function bgImageChanger(element) {
    if ($(element).data('image') !== "") {
        $('.config-reference-image', $(element).closest('.tab-pane')).css({
            "background-image": "url("+$(element).data('image')+")",
            "background-position": $(element).data('imagealignment')
        });
    }
}

function clickLogic(element) {
    if ($(element).attr('type') !== "checkbox") {
        let parent = $(element).data('parent');
        let fixedValue = $(element).data('fixed-value');
        let sameLevel = $('[data-parent="' + parent + '"]');

        $.each(sameLevel, function () {
            let subOptionsContainer = $('[data-parentoption="' + $(this)[0].id + '"]');
            if ($(this)[0].id !== $(element)[0].id) {
                $(this).prop('checked', false);
                clickLogicDisplay($(this))

                if (subOptionsContainer.length) {
                    subOptionsContainer.addClass('d-none');
                    $('input:radio', subOptionsContainer).each(function() {
                        $(this).prop('checked', false);
                        clickLogicDisplay($(this));
                    })
                }
            } else {
                $(this).prop('checked', true);

                if (subOptionsContainer) {
                    subOptionsContainer.removeClass('d-none');
                    if (fixedValue !== "") {
                        subOptionsContainer.find('input#option-' + fixedValue).click();
                    }
                }
            }
        });
    }

    clickLogicDisplay(element);
}

function clickLogicDisplay(element) {
    let refId = $(element).data('id');
    let triggerGrayElements = $(".config-overview [data-gray-helper*='|" + refId + "|']");
    let triggerHideElements = $(".config-overview [data-hide-helper*='|" + refId + "|']");
    let triggerShowElements = $(".config-overview [data-show-helper*='|" + refId + "|']");
    let triggerDisableElements = $(".config-overview [data-disable-helper*='|" + refId + "|']");

    if ($(element).prop('checked')) {
        triggerHideElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-hide') || '';
            ref.attr('data-hide', refData.replace("|" + refId + "|", "") + "|" + refId + "|");
        })

        triggerShowElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-show') || '';
            ref.attr('data-show', refData.replace("|" + refId + "|", "") + "|" + refId + "|");
        })

        triggerDisableElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-disable') || '';
            ref.attr('data-disable', refData.replace("|" + refId + "|", "") + "|" + refId + "|");
        })

        triggerGrayElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-gray') || '';
            ref.attr('data-gray', refData.replace("|" + refId + "|", "") + "|" + refId + "|");
        })

        $('.config-overview [data-hide]:not([data-hide=""])').addClass('d-none');
        $('.config-overview [data-show]:not([data-show=""])').removeClass('d-none');
        $('.config-overview [data-disable]:not([data-disable=""])').attr('disabled', 'disabled');
        $('.config-overview [data-gray]:not([data-gray=""])').addClass('color-gray');
    } else {
        triggerHideElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-hide') || '';
            ref.attr('data-hide', refData.replace("|" + refId + "|", ''));
        })

        triggerShowElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-show') || '';
            ref.attr('data-show', refData.replace("|" + refId + "|", ''));
        })

        triggerDisableElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-disable') || '';
            ref.attr('data-disable', refData.replace("|" + refId + "|", ''));
        })

        triggerGrayElements.each(function (idx, element) {
            let ref = $(element);
            let refData = ref.attr('data-gray') || '';
            ref.attr('data-gray', refData.replace("|" + refId + "|", ''));
        })

        $('.config-overview [data-hide]:is([data-hide=""])').removeClass('d-none');
        $('.config-overview [data-show]:is([data-show=""])').addClass('d-none');
        $('.config-overview [data-disable]:is([data-disable=""])').removeAttr('disabled');
        $('.config-overview [data-gray]:is([data-gray=""])').removeClass('color-gray');
    }
}

function saveConfigToSession() {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = ($(el).is(':disabled') ? false : $(el).is(":checked"));
        fields.push(valueName);
        values.push(value);
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/setConfigData",
        type: 'post',
        data: {
            'project': $('.config-overview').data('config-project'),
            'phase': $('.config-overview').data('config-phase'),
            'unit': $('.config-overview').data('config-unit'),
            'configuration': $('.config-overview').data('config-configuration'),
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'userconfig_id': "",
            'userconfig_name': ""
        }
    });
}

function saveConfigToUser() {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;
    let name = $('#save_config_name').val() ?? "";

    $('input#save_config_name').prop('disabled', true);
    $('button#save_config').prop('disabled', true);

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = ($(el).is(':disabled') ? false : $(el).is(":checked"));
        fields.push(valueName);
        values.push(value);

        if($(el).prop('checked') && $(el).not(':disabled') && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/user/saveConfigData",
        type: 'post',
        data: {
            'project': $('.config-overview').data('config-project'),
            'phase': $('.config-overview').data('config-phase'),
            'unit': $('.config-overview').data('config-unit'),
            'configuration': $('.config-overview').data('config-configuration'),
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'name': name,
            'price': (parseInt(livingUnitPrice) + parseInt(configPrice))
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            $('input#save_config_name').prop('disabled', false);
            $('button#save_config').prop('disabled', false);

        }
    });
}

function updateConfigToUser() {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;
    let name = $('#update_config_name').val() ?? "";
    let id = $('#update_config_id').val() ?? "";

    $('input#update_config_name').prop('disabled', true);
    $('button#update_config').prop('disabled', true);

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = ($(el).is(':disabled') ? false : $(el).is(":checked"));
        fields.push(valueName);
        values.push(value);

        if($(el).prop('checked') && $(el).not(':disabled') && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/user/updateConfigData",
        type: 'post',
        data: {
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'name': name,
            'id': id,
            'price': (parseInt(livingUnitPrice) + parseInt(configPrice))
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            $('input#update_config_name').prop('disabled', false);
            $('button#update_config').prop('disabled', false);
        }
    });
}

function updateConfigToUserFromManager() {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;
    let name = $('#update_config_name').val() ?? "";
    let id = $('#update_config_id').val() ?? "";

    $('input#update_config_name').prop('disabled', true);
    $('button#update_config').prop('disabled', true);

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = ($(el).is(':disabled') ? false : $(el).is(":checked"));
        fields.push(valueName);
        values.push(value);

        if($(el).prop('checked') && $(el).not(':disabled') && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/admin/updateConfigData",
        type: 'post',
        data: {
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'name': name,
            'id': id,
            'price': (parseInt(livingUnitPrice) + parseInt(configPrice))
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            $('input#update_config_name').prop('disabled', false);
            $('button#update_config').prop('disabled', false);
        }
    });
}

function resetConfig(e) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/user/resetConfig",
        type: 'post',
        data: {
            'sessionName': $(e.target).data('sessionName'),
            'test': 1
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            console.log(data);
        }
    });
}

function reservateConfigToUser(name="") {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;

    $('button#reservate_object').prop('disabled', true);

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = $(el).prop('checked');
        fields.push(valueName);
        values.push(value);
        if($(el).prop('checked') && !$(el).attr("disabled") && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/user/reservateConfigData",
        type: 'post',
        data: {
            'project': $('.config-overview').data('config-project'),
            'phase': $('.config-overview').data('config-phase'),
            'unit': $('.config-overview').data('config-unit'),
            'configuration': $('.config-overview').data('config-configuration'),
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'name': name,
            'price': (parseInt(livingUnitPrice) + parseInt(configPrice))
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            $('button#reservate_object').prop('disabled', false);
        }
    });
}

function markFinished(name="") {
    let fields = [];
    let values = [];
    let categories = [];
    let categories_state = [];
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;

    $('button#final-config-save-btn').prop('disabled', true);

    $('[data-price]').each((idx, el) => {
        let valueName = $(el).attr('name');
        let value = $(el).prop('checked');
        fields.push(valueName);
        values.push(value);
        if($(el).prop('checked') && !$(el).attr("disabled") && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.cat-finished').each((idx, el) => {
        categories.push($(el).attr('name'));
        categories_state.push($(el).val());
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: "/user/markFinished",
        type: 'post',
        data: {
            'project': $('.config-overview').data('config-project'),
            'phase': $('.config-overview').data('config-phase'),
            'unit': $('.config-overview').data('config-unit'),
            'configuration': $('.config-overview').data('config-configuration'),
            'fields': JSON.stringify(fields),
            'values': JSON.stringify(values),
            'categories': JSON.stringify(categories),
            'categories_state': JSON.stringify(categories_state),
            'name': name,
            'price': (parseInt(livingUnitPrice) + parseInt(configPrice))
        },
        success: function(data) {
            location.reload();
        },
        error: function(data) {
            $('button#final-config-save-btn').prop('disabled', false);
        }
    });
}

function getConfigPrice() {
    let livingUnitPrice = $('[data-config-price]').data('config-price');
    let configPrice = 0;

    $('[data-price]').each((idx, el) => {
        if($(el).prop('checked') && !$(el).attr("disabled") && $(el).data('price')) {
            configPrice = (configPrice + parseInt($(el).data('price')));
        }
    });

    $('.price-config').html(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(parseInt(configPrice)))
    $('.price-sum').html(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((parseInt(livingUnitPrice) + parseInt(configPrice))))
}

function checkFields() {
    $('.config-overlay').each(function() {
        let incomplete = false;
        let mandatoryFields = $(this).find('.mandatory-field').not('.d-none').not(':disabled');

        $.each(mandatoryFields, function() {
            if($(this).is(":checkbox")) {
                if($(this).prop('checked') === false) {
                    incomplete = true;
                    $(this).closest(".option").find(".mandatory-field-text").removeClass('d-none');
                }
                else {
                    $(this).closest(".option").find(".mandatory-field-text").addClass('d-none');
                }
            }
            else {
                if($(this).closest(".options-group").find("input:checked:last").length === 0 || $(this).closest(".options-group").find("input:checked:last").hasClass('withsuboptions')) {
                    incomplete = true;
                    $(this).closest(".option").find(".mandatory-field-text").removeClass('d-none');
                }
                else {
                    $(this).closest(".option").find(".mandatory-field-text").addClass('d-none');
                }
            }
        })

        $.each($.find('input.withsuboptions:checked:not(:disabled)', this), function() {
            let subSelected = false;

            $.each($.find('input[data-parent="option-'+$(this).data('id')+'"]:not(:disabled)'), function() {
                if($(this).prop('checked')) {
                    subSelected = true;
                }
            });
            if(!subSelected) {
                incomplete = true;

                let thisMandatory = $(this).closest(".options-group")

                if(thisMandatory.length > 0) {
                    $.each(thisMandatory, function() {
                        let info = $(this).find('.option-information > .mandatory-field-text:first')
                        if(info.length > 0) {
                            if($(info[0]).hasClass('d-none')) {
                                $(info[0]).removeClass('d-none')
                            }
                        }
                        else {
                            let parentMandatory = $(this).parent().closest(".options-group")
                            let parentInfo = $(parentMandatory[0]).find('.option-information > .mandatory-field-text:first')
                            if(parentInfo.length > 0) {
                                if($(parentInfo[0]).hasClass('d-none')) {
                                    $(parentInfo[0]).removeClass('d-none')
                                }
                            }
                        }
                    });
                }
            }
        })

        if(incomplete === true) {
            $('.config-btn--finish', $(this)).removeClass('cda-button__main cda-button__main--success').addClass('cda-button__light cda-button__light--disabled notFinished');
            $(this).prev().find('.config-overview-tile').removeClass('config-tile-done');
            $(this).prev().find('.cat-finished').val(0);
        }
        else {
            $('.config-btn--finish', $(this)).addClass('cda-button__main cda-button__main--success').removeClass('cda-button__light cda-button__light--disabled notFinished');
        }
    })
}

function finishPart(event) {
    if($(event.target).hasClass('d-none')) {
        $(event.target).closest('.config-overlay').prev().find('.config-overview-tile').removeClass('config-tile-done');
        $(event.target).closest('.config-overlay').prev().find('.cat-finished').val(0);
        event.preventDefault();
    }
    else {
        $(event.target).closest('.config-overlay').prev().find('.config-overview-tile').addClass('config-tile-done');
        $(event.target).closest('.config-overlay').prev().find('.cat-finished').val(1);
        $('.js-config-overlay').removeClass('config-overlay--active');
    }
    saveConfigToSession();
}

function openConfigOverlay() {
    $('.config-overlay-btn').click(function(e) {
        e.preventDefault();
        $('div.'+$(this).data('ref')).addClass('config-overlay--active');
        $('body').addClass('overlay-open');
    })
}

function closeConfigOverlay() {
    $('.js-config-overlay__close-button').click(function(e) {
        e.preventDefault();
        $('.js-config-overlay').removeClass('config-overlay--active');
        $('body').removeClass('overlay-open');
    })
}
